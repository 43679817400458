.pdf-template {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  margin: auto;
  width: 210mm;
  min-height: 297mm;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 1rem auto;
}
.pdf-template header {
  display: flex;
  padding: 10px;
  gap: 20px;
}
.pdf-template main {
  margin: 0 20px;
}
.title {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title h1 {
  font-size: 24px;
  margin-bottom: 15px;
}
.title h2 {
  font-size: 18px;
}
.form-control {
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
ol {
  list-style: decimal inside;
}
.bank-details,
.present-address,
.enclosures {
  padding: 10px;
}
.bank-details > span,
.present-address > span,
.enclosures > span {
  font-weight: 600;
  padding-bottom: 8px;
}
footer {
  margin-top: 40px;
  padding: 20px;
}

@media print {
  .pdf-template {
    page-break-after: always;
    border: none;
    margin: 0;
  }
}

.signs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.page {
  margin-top: 20px;
  display: flex;
  gap: 1rem;
}
.field-section {
  width: 120mm;
}
.aadhar-section {
  width: 75mm;
  height: 150mm;
}
.candidate-sign {
  width: 200px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.passport-photo {
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 51mm;
  width: 51mm;
  flex-shrink: 0;
}

.certified-main {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}

.certified-value {
  flex-grow: 100;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 5px;
  font-weight: bold;
  color: #c60260;
}
.certified-pdf-template {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  margin: auto;
  width: 210mm;
  min-height: 250mm;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 1rem auto;
}

@media print {
  .certified-pdf-template {
    page-break-after: always;
    border: none;
    margin: 0;
  }
}
