* {
  box-sizing: border-box;
}
@font-face {
  font-family: CenturyGothic;
  src: url(/public/files/CenturyGothic.ttf);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.marquee-container {
  overflow: hidden;
  width: 100%;
  height: 200px; /* Adjust the height as needed */
}

.marquee {
  display: flex;
  flex-direction: column-reverse; /* Scroll-up direction */
  animation: marquee 10s linear infinite; /* Adjust the duration as needed */
}

@keyframes marquee {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(
      -100%
    ); /* Adjust the value to control the scrolling speed */
  }
}

.marqueImage {
  margin-bottom: 10px; /* Adjust the value to decrease the space */
}




@media print {
  header, footer {
    display: none;
  }
  
  .print-hide {
    display: none !important;
  }
  .print-margin{
    margin-top: 3%;
    margin-bottom: 35%;
  }
  
}
