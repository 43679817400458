.header {
  display: flex;
  padding: 10px;
  gap: 20px;
}
.page {
  margin-top: 20px;
  display: flex;
  gap: 1rem;
}
.field-section {
  width: 60%;
}
.aadhar-section {
  width: 40%;
}
.candidate-sign {
  width: 240px;
  height: 140px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.passport-photo {
  border: 1px solid black;
  height: 51mm;
  width: 51mm;
  flex-shrink: 0;
}
.title {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title h1 {
  font-size: 24px;
  margin-bottom: 15px;
}
.title h2 {
  font-size: 18px;
}
.form-control {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
ol {
  list-style: decimal inside;
}
.bank-details,
.present-address,
.enclosures {
  padding: 10px;
}
.bank-details > span,
.present-address > span,
.enclosures > span {
  font-weight: 600;
  padding-bottom: 8px;
}
footer {
  margin-top: 40px;
  padding: 20px;
}
.signs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
